/**
 * This is the entrypoint to the JS in your app.
 * ES6 features are supported inside this file.
 */
/**

/*******************************************
 * Requires
*******************************************/
/**
 * jquery
 */
window.jQuery = require('jquery');
window.$ = window.jQuery; // for use in this file
/**
 * bxSlider for home-banner.js
 */
var bxSlider = require('bxslider');


/*******************************************
 * Imports
*******************************************/
/**
 * Utility
 */
import { Utility } from './modules/utility.js';
/**
 * Navscripts
 */
import { navScripts } from './modules/nav.js';
/**
 * HomeBanner
 */
import { HomeBanner } from './modules/home-banner.js';
/**
 * Banner
 */
import { ResizeBanner } from './modules/resize-banner.js';
/**
 * Accordion
 */
import { Accordion } from './modules/accordion.js';
/**
 * ScrollAnimations
 */
import { ScrollAnimations } from './modules/scroll-animations.js';


/*******************************************
 * Document Ready Function
*******************************************/
$(document).ready(function() {
  /**
  * Initialize navigation scripts
  */
    navScripts();
  /**
  * Initialize LoadAllImages subscription
  */
    Utility.loadAllImages();
  /**
  * Initialize Banner subscriptions
  */
    ResizeBanner.init();
  /**
  * Initialize HomeBanner subscriptions
  */
    HomeBanner.init();
  /**
  * Initialize smooth subscription
  */
    Utility.smoothScroll();
  /**
  * Initialize accordion subscriptions
  */
    Accordion.accordionWatch();
  /**
  * Initialize scroll animations
  */
    ScrollAnimations.init();
});

/*******************************************
 * Window Event Subscriptions
*******************************************/
/**
 * The event subscription that reloads images on resize.
 */
window.addEventListener('resize', function(el) {
  Utility.loadAllImages();
});
