/**
 * Require jquery
 */
window.jQuery = require('jquery');
window.$ = window.jQuery;

/**
 * Imports
 */
// Utility
import {
    Utility
} from './utility.js';


/**
 * @exports ResizeBanner
 */

var ResizeBanner = {

  /**
   * Initialize the banner scripts
   * @method init
   * @memberof ResizeBanner
   */
  init: function() {
    //cache this object
    var thisObject = this;

    Utility.detectMobile();

    if(jQuery.browser.mobile == false) {
      // console.log("not a mobile browser");
    } else {
      // return; // don't run on mobile browsers
    }

    // Only run if home banner exists
    if($("#banner-area.banner-area-home").length > 0) {
      thisObject.bannerSizes();

    } else {
      return; // not home page
    }


  },

  /**
   * Resizes the home banner
   * @method resizeTheBanner
   * @memberof ResizeBanner
   */
  resizeTheBanner: function() {
    Utility.resizeElement('#banner-area.banner-area-home', window, function(){Utility.loadAllImages();});
    Utility.resizeElement('ul#home-banner .home-banner-slide', window, function(){Utility.loadAllImages();});
  },

  /**
   * Sets the heights for the banner elements
   * @method setBannerElHeight
   * @memberof ResizeBanner
   */
  setBannerElHeight: function ($elOne, $elTwo, height) {
    // function that adds the heights
    function addCssHeight() {
      $elOne.css({
        minHeight : height
      });
      $elTwo.css({
        minHeight : height
      });
    }
    $.when(addCssHeight()).then(function() {Utility.loadAllImages()});

  },

  /**
   * Sets the banner height for large screens
   * @method largeScreenHeight
   * @memberof ResizeBanner
   */
  largeScreenHeight: function() {

    //cache this object
    var thisObject = this;
    //cache home banner
    var $bannerAreaHome = $('#banner-area.banner-area-home');
    // cache banner slides
    var $bannerSlides = $('ul#home-banner .home-banner-slide');
    // height for large screens
    var wideHeight = 600;

    thisObject.setBannerElHeight($bannerAreaHome,$bannerSlides,wideHeight);
  },

  /**
   * Sets the banner height for small screens
   * @method smallScreenHeight
   * @memberof ResizeBanner
   */
  smallScreenHeight: function() {

    //cache this object
    var thisObject = this;
    //cache home banner
    var $bannerAreaHome = $('#banner-area.banner-area-home');
    // cach banner slides
    var $bannerSlides = $('ul#home-banner .home-banner-slide');
    // height for small screens
    var smallHeight = 870;

    thisObject.setBannerElHeight($bannerAreaHome,$bannerSlides,smallHeight);
  },

  /**
   * Sets min and max heights for banner based on screen size
   * @method bannerSizes
   * @memberof ResizeBanner
   */
  setTheBannerHeight : function() {
    // get window width here. Doesn't work with cached version outside of this function
    var $windowWidth = $(window).width(); // jquery object
    // breakpoint
    var breakpoint = 767;
    //cache this object
    var thisObject = this;

    if($windowWidth > breakpoint) {
      thisObject.largeScreenHeight();
      // console.log($windowWidth + "is greater than breakpoint");
    } else {
      thisObject.smallScreenHeight();
      // console.log($windowWidth + "is less than breakpoint");
    }
  },

  /**
   * Sets min and max heights for banner based on screen size
   * @method bannerSizes
   * @memberof ResizeBanner
   */
  bannerSizes: function() {

    //cache document object
    var $document = $(document);

    //cache window object
    var $window = $(window);

    //cache this object
    var thisObject = this;

    // run when document loads
    thisObject.resizeTheBanner(); // Resize the banner
    thisObject.setTheBannerHeight(); // set heights on window load
    Utility.resizeElement(
      '#banner-area .bx-viewport',
      '#banner-area.banner-area-home',
      function(){}
    );//resize the .bx-viewport only on load. the .bx-viewport causes sizing problems otherwise.

    // run the following when the window resizes:
    $window.resize(function() {
        thisObject.resizeTheBanner(); // Resize the banner
        thisObject.setTheBannerHeight(); // Set heights if needed
    });
  }

};

export {
    ResizeBanner
};
