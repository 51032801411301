/**
 * Require jquery
 */
var $ = require('jquery');


/**
 * @exports navScripts
 */

// Bind all scripts in one function to load
var navScripts = function() {
    mainNav();
    mobileNav();
}

/******************************
 ** Main Navigation Functions **
 ******************************/
 // Top level function for Main Nav scripts
 function mainNav() {
     showMainSubNav();
     hideMainSubNav();
     stickyNav();
 }

// Show the Main Navigation Subnav on hover
function showMainSubNav() {
    $('#main-nav ul li').mouseenter(
        function() {
            // Rotate subnav arrow
            $(this).children('.folder').addClass('folder-open');
            // Slide subnav down
            $(this).children('.subnav').hide().slideDown(100);
            return;
        }
    );
    return;
}
// Hide the Main Navigation Subnav when not hovering
function hideMainSubNav() {
    $('#main-nav ul li').mouseleave(
        function() {
            // Rotate subnav arrow back
            $(this).children('.folder').removeClass('folder-open');
            // Slide subnav up
            $(this).children('.subnav').slideUp(50);
            return;
        }
    );
    return;
}
// Make the Main navbar sticky
function stickyNav() {
    var anchor = $(".nav-anchor");
    var header = $(".header");
    var breakPoint;
    if ($(window).width() >= 767) { // for wide screens
      breakPoint = $('#header').height();//$('#banner-area-wrapper').height() - 50;
    } else { // for small screens
      breakPoint = $('#header').height();//$('#banner-area-wrapper').height() - 100;
    }


    // Shows the header by removing the hidden class
    function showHeader() {
      header.removeClass('hidden');
    }

    // Makes the navigation sticky.
    function addSticky() {
      header.addClass('sticky'); // Give header a background color
      anchor.addClass('scrolled'); // Makes nav-anchor have fixed position
    }

    // Resets the navigation to the top of the document
    function removeSticky() {

      function rmvStickyClass() {
        header.removeClass("sticky");
        anchor.removeClass('scrolled');
      }

      $.when( rmvStickyClass() )
        .then( resetAnimation() );
    }

    // Fades the header using animate.css classes
    function fadeHeader() {
      header.addClass('animated fadeOut');
    }

    // Resets the header for future use of animate.css classes
    function resetAnimation() {
      header.removeClass('animated fadeOut');
    }

    $(window).scroll(function() {
        if ($(this).scrollTop() > breakPoint) {
            $.when(addSticky()).then( showHeader() );
        } else {
            $.when(fadeHeader()).then( removeSticky() );
        }
    });
}

/********************************
 ** Mobile Navigation Functions **
 ********************************/
// Toggle the Mobile Navigation
function toggleMobileNav() {
    $('.mobile-nav-wrapper').toggleClass('hidden');
    return;
}
// Toggle the Clicked class for the header
function toggleHeaderClicked() {
    $('.header').toggleClass('clicked'); // Adds a background color
    return;
}
// Function for when the Nav Icon is clicked
function navIconClick() {
    $('#nav-icon').click(function() {
        $(this).toggleClass('nav-icon-open');
        // Toggle the mobile navigation
        toggleMobileNav();
        toggleHeaderClicked();
        // run my code
        return false;
    });
    return
}
// Toggle the Mobile Subnav when clicked
function toggleMobileSubNav() {
    $('#mobile-nav ul li .folder').click(function() {
        // Toggle folder arrow direction
        $(this).toggleClass('folder-open');
        // Slide subnav down or up
        $(this).siblings('.subnav').slideToggle(200);
    });
    return;
}
// Top level function for mobile navigation
function mobileNav() {
    // Functions that make mobile navigation work
    navIconClick();
    toggleMobileSubNav();
    return;
}

/******************************
 ** Export navScripts function **
 ******************************/
export {
    navScripts
};
