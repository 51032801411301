/**
 * Require jquery
 */
window.jQuery = require('jquery');
window.$ = window.jQuery;


/**
 * Require bxSlider
 */
var bxSlider = require('bxslider');


/**
 * Imports
 */
// Utility
import {
    Utility
} from './utility.js';


/**
 * @exports HomeBanner
 */

var HomeBanner = {

    /**
     * Start the slippry slider with options
     * @method init
     * @memberof HomeBanner
     */
    init: function() {

        // Reload banner images
        function reloadBannerImages() {
            $('#home-banner img[data-src]').each(function() {
                ImageLoader.load(this);
                //  console.log('banner images reloaded');
            });
        }

        // Show and hide content
        function contentShowHide() {
            // Remove hidden class from slider images
            $('#home-banner .home-banner-image').removeClass('hidden');
            // Add hidden class to fallback image
            $('#banner-area .main-image').addClass('hidden');
        }

        var homeBanner = $('#home-banner').bxSlider({

            //options
            auto: true,
            mode: 'fade',
            controls: false,
            pager: false,
            responsive: true,
            preloadImages: 'all',
            easing: 'ease-in',
            onSliderLoad: function(el) {

                //show and hide content
                contentShowHide();

                //reload banner images
                reloadBannerImages();

                return this;
            },
            onSlideBefore: function(el) {
                reloadBannerImages();
            },
            onSlideAfter: function(el) {
                reloadBannerImages();
            }

        });

        if ($('#home-banner').length > 0) {

            // Start the slider if it exists
            homeBanner;

        } else {
            // Slider doesn't exist
            return;
        }
    }
};

export {
    HomeBanner
};
