/*******************************************
 * Requires
*******************************************/
/**
 * jquery
 */
window.jQuery = require('jquery');
window.$ = window.jQuery; // for use in this file

/**
 * @exports ScrollAnimations
 */

var ScrollAnimations = {

    /**
     * Initialize scroll animations events
     * @method init
     * @memberof ScrollAnimations
     */
    init: function() {
      // For home page service are section
      this.animateOnScroll('#service-areas ul > li', -150, 'fadeInRight');
      this.animateOnScroll('#service-areas hr', -150, 'fadeInRight');
      // For home page testimonials
      this.animateOnScroll('.quote', -150, 'fadeInRight');
      // For home page affiliates
      this.animateOnScroll('.affiliate-logo', -150, 'fadeIn');
      // For home page awards
      this.animateOnScroll('#awards-top', -150, 'fadeInLeft');
      this.animateOnScroll('#awards-bottom', -150, 'fadeInRight');
      // For index page custom post types
      this.animateOnScroll('.element-fadeIn', -150, 'fadeIn');
      this.animateOnScroll('.element-fadeInRight', -150, 'fadeInRight');
      this.animateOnScroll('.element-fadeInLeft', -150, 'fadeInLeft');
      this.animateOnScroll('.element-fadeInDown', -150, 'fadeInDown');
      this.animateOnScroll('.element-fadeInUp', -150, 'fadeInUp');
      // For quick links wrapper sticky toggle
      this.checkOffset('#quick-links-wrapper', '#footer-wrapper', 'stick2footer');
    },

    // $(window).scroll(function() {
    //     $('.test-cta').each(function() {
    //         var imagePos = $(this).offset().top;
    //
    //         var topOfWindow = $(window).scrollTop();
    //         if (imagePos < topOfWindow + 500) {
    //             $(this).addClass('fadeInDown');
    //         }
    //     });
    // });

    /**
     * Animates an element with css class
     * @method animateOnScroll
     * @memberof ScrollAnimations
     * $window variable depends on windowWatch function
     */
    animateOnScroll: function(elString, offsetHeight, animationString) {

      // Cache elString parameter as jquery object
      var $elString = $(elString);

      // Only run if element exists on the page
      if($elString.length > 0) {

        // Cache window object
        var $window = $(window);

        // Hide the element for the animation effect
        $elString.css({opacity : 0});

        $window.scroll(function() {

          $elString.each(function() {

            // Get position of element
            var elPos = $(this).offset().top;

            // Get top of window
            var topOfWindow = $window.scrollTop();

            //Get window height
            var windowHeight = $window.height();

            // Animate element if position is less than offset
            if (elPos < topOfWindow + windowHeight + offsetHeight) {
                $(this).addClass(animationString);
            }

          });
        });
      } else {
        return; // element doesn't exist
      }

    },
    /**
     * Toggles css class based on the position of an element on the page
     * @method checkOffset
     * @memberof ScrollAnimations
     * @parameters: el is an element id or class; thresholdEl is the
     * threshold element id or class; classNameString is a string of the css
     * class to toggle.
     */
  	checkOffset: function (el,thresholdEl,classNameString) {
      // set parameters as jquery objects
      var $el = $(el);
      var $thresholdEl = $(thresholdEl);
      var className = classNameString;
      // Cache window object
      var $window = $(window);
      // only run if the el parameter exists
      if($el.length > 0) {
        $window.scroll(function() {
          if($el.offset().top > $thresholdEl.offset().top) {
      			$el.addClass(className); // move to bottom when scrolling into thresholdEl
      		}
      		else if($(document).scrollTop() + window.innerHeight < $thresholdEl.offset().top) {
      			$el.removeClass(className);// restore when you scroll up past thresholdEl
      		}
        });
      } else {
        return; // element doesn't exist
      }
  	}

};

export { ScrollAnimations };
