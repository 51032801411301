// Import Utility
import { Utility } from './utility.js';

/**
 * @exports Accordion
 */

var Accordion = {

  /**
   * Initializes all accordion scripts
   * @method accordionWatch
   * @memberof Accordion
   */
  accordionWatch: function() {

    /**
     * Scrolls to a section
     * @function scroll_to_section
     */
    function scroll_to_section(el) {
      $('html, body').animate({
          scrollTop: el.offset().top - $('#header').height()
        },300);
      // console.log("scrolled to " + el.attr('href') + " section");
    }

    /**
     * Closes all accordion sections
     * @function close_accordion_section
     */
    function close_accordion_section() {
      $('.accordion-inner .accordion-section-title').removeClass('active');
      $('.accordion-inner .accordion-section-title').removeClass('flipButton');
      $('.accordion-inner .accordion-section-content').slideUp(300).removeClass('open');
    }

    /**
     * Opens an accordion section
     * @function open_accordion_section
     */
    function open_accordion_section(el) {
      // Grab current anchor value
      var currentAttrValue = el.attr('href');
      // Add active class to section title
      el.addClass('active');
      // Add flipButton class to section title
      el.addClass('flipButton');
      // Open up the hidden content panel
      $('.accordion-inner ' + currentAttrValue).slideDown(300,function(){
        // scroll to element
        scroll_to_section(el);
      }).addClass('open')
      // Fire a resize event that in turn fires the loadAllImages script
      Utility.corticaFireResize();
    }

    /**
     * Watches for Accordion click and toggles visiblity
     * @function close_accordion_section
     * @memberof Accordion
     */
    $('.accordion-section-title').click(function(e) {
      // prevent link clicking
      e.preventDefault();

      // get this element
      var $thisEl = $(this);

      if ($(e.target).is('.active')) {
        $.when(
          // close all accordions
          close_accordion_section()
        ).then(
          // scroll to element
          scroll_to_section($thisEl)
        );
      } else {
        $.when(
          // close all accordions
          close_accordion_section()
        ).then(
          // open $thisEl accordion section
          open_accordion_section($thisEl)
        )
      }
      return false;
      });
  },

};

export { Accordion };
